import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { VerifyUser } from "../GraphQL/Mutations/VerifyUser";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { GetStaticPage } from "../GraphQL/Queries/GetStaticPage";
import { StaticPageUpdate } from "../GraphQL/Mutations/StaticPageUpdate";

export default function AboutUs() {
    const [type, setType] = useState('about_us');
    const [body, setBody] = useState('');
    const [title, setTitle] = useState('');
    const [heading, setHeading] = useState('');
    const [subHeading, setSubHeading] = useState('');
    const { data, loading, error } = useQuery(GetStaticPage, {
        variables: {
          type: type,
        },
    });
    const [UpdateStaticPage] = useMutation(StaticPageUpdate);
    //console.log(data);

    useEffect(() => {
        if(data?.getStaticPage?.title != null && data?.getStaticPage?.title != undefined && data?.getStaticPage?.title) {
            setTitle(data?.getStaticPage?.title)
        }
        if(data?.getStaticPage?.body != null && data?.getStaticPage?.body != undefined && data?.getStaticPage?.body) {
          /* setHeading(data?.getStaticPage?.body[0]?.heading)
          setSubHeading(data?.getStaticPage?.body[0]?.sub_heading)
          setBody(data?.getStaticPage?.body[0]?.html) */
          setHeading(data?.getStaticPage?.body.heading)
          setSubHeading(data?.getStaticPage?.body.sub_heading)
          setBody(data?.getStaticPage?.body.html)
        }
    }, [data])

  return (
    <div className="max-w-[1600px] mx-auto p-5 mt-5">
      <div className="grid grid-cols-1 gap-10  text-left">
        <div className="w-full text-left">
            <h6 className="font-bold mb-2">Page Title</h6>
            <input value={title} onChange={(e) => { setTitle(e.target.value) }} className="w-full border px-3 py-2"/>
        </div>
        <div className="w-full text-left">
            <h6 className="font-bold mb-2">Heading</h6>
            <input value={heading} onChange={(e) => { setHeading(e.target.value) }} className="w-full border px-3 py-2"/>
        </div>
        <div className="w-full text-left">
            <h6 className="font-bold mb-2">Sub Heading</h6>
            <input value={subHeading} onChange={(e) => { setSubHeading(e.target.value) }} className="w-full border px-3 py-2"/>
        </div>
        <div className="w-full text-left">
            <h6 className="font-bold mb-2">Page Body</h6>
            <ReactQuill theme="snow" value={body} onChange={setBody} className="h-[400px]"/>
        </div>
        <div className="mt-5">
            <button 
                className="inline-block px-7 py-3 mr-2 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                onClick={async () => {
                    //console.log(title);
                    //console.log(JSON.stringify(body));

                    await UpdateStaticPage({
                        variables: {
                            type: type,
                            title: title,
                            body: {
                                heading: heading,
                                sub_heading: subHeading,
                                html: body
                              },
                        },
                        refetchQueries: [
                          {
                            query: GetStaticPage,
                            variables: {
                                type: type,
                            },
                          },
                        ],
                      })
                    .then((res) => {
                        console.log(res);
                    })
                    .catch((err) => console.error(err));
                }}
            >Update</button>
        </div>
      </div>
    </div>
  );
}
