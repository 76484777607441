import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { VerifyUser } from "../GraphQL/Mutations/VerifyUser";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { GetStaticPage } from "../GraphQL/Queries/GetStaticPage";
import { StaticPageUpdate } from "../GraphQL/Mutations/StaticPageUpdate";

export default function HowItWorks() {
    const [type, setType] = useState('how_it_works');
    const [body, setBody] = useState('');
    const [title, setTitle] = useState('');

    const [conclusion, setConclusion] = useState('');
    const [nftWorks, setNftWorks] = useState('');
    const [nft, setNft] = useState('');
    const [nftAndWorks, setNftAndWorks] = useState('');
    const { data, loading, error } = useQuery(GetStaticPage, {
        variables: {
          type: type,
        },
    });
    const [UpdateStaticPage] = useMutation(StaticPageUpdate);
    //console.log(data);

    useEffect(() => {
        if(data?.getStaticPage?.title != null && data?.getStaticPage?.title != undefined && data?.getStaticPage?.title) {
            setTitle(data?.getStaticPage?.title)
        }
        if(data?.getStaticPage?.body != null && data?.getStaticPage?.body != undefined && data?.getStaticPage?.body) {
            
            setNft(data?.getStaticPage?.body?.nft ? data?.getStaticPage?.body?.nft : '');

            setNftAndWorks(data?.getStaticPage?.body?.nftAndWorks ? data?.getStaticPage?.body?.nftAndWorks : '');

            setNftWorks(data?.getStaticPage?.body?.nftWorks ? data?.getStaticPage?.body?.nftWorks : '');

            setConclusion(data?.getStaticPage?.body?.conclusion ? data?.getStaticPage?.body?.conclusion : '');
          
        }
    }, [data])

  return (
    <div className="max-w-[1600px] mx-auto p-5 mt-5">
      <div className="grid grid-cols-1 gap-10  text-left">
        
        <div className="w-full text-left">
            <h6 className="font-bold mb-2">Page Title</h6>
            <input value={title} onChange={(e) => { setTitle(e.target.value) }} className="w-full border px-3 py-2"/>
        </div>
        
        <div className="w-full text-left">
            <h6 className="font-bold mb-2">What is NFT and How it Works</h6>
            <textarea rows={4} onChange={(e) => { setNftAndWorks(e.target.value) }} className="w-full border px-3 py-2" defaultValue={nftAndWorks}></textarea>
        </div>

        <div className="w-full text-left">
            <h6 className="font-bold mb-2">What is an NFT?</h6>
            <textarea rows={4} onChange={(e) => { setNft(e.target.value) }} className="w-full border px-3 py-2" defaultValue={nft}></textarea>
        </div>

        <div className="w-full text-left">
            <h6 className="font-bold mb-2">How do NFTs work?</h6>
            <ReactQuill theme="snow" value={nftWorks} onChange={setNftWorks} className="h-[240px]"/>
        </div>

        <div className="w-full text-left mt-10">
            <h6 className="font-bold mb-2">Conclusion</h6>
            <textarea rows={4} onChange={(e) => { setConclusion(e.target.value) }} className="w-full border px-3 py-2" defaultValue={conclusion}></textarea>
        </div>
        <div className="mt-5">
            <button 
                className="inline-block px-7 py-3 mr-2 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                onClick={async () => {
                    //console.log(title);
                    //console.log(JSON.stringify(body));

                    await UpdateStaticPage({
                        variables: {
                            type: type,
                            title: title,
                            body: {
                                nftAndWorks: nftAndWorks,
                                nft: nft,
                                nftWorks: nftWorks,
                                conclusion: conclusion
                            },
                        },
                        refetchQueries: [
                          {
                            query: GetStaticPage,
                            variables: {
                                type: type,
                            },
                          },
                        ],
                      })
                    .then((res) => {
                        console.log(res);
                    })
                    .catch((err) => console.error(err));
                }}
            >Update</button>
        </div>
      </div>
    </div>
  );
}
