import { useQuery } from "@apollo/client";
import React from "react";
import { GetALLNft } from "../GraphQL/Queries/GetALLNft";
import { GetAllUsers } from "../GraphQL/Queries/GetAllUsers";

export default function Approve() {
  const { data, loading, error } = useQuery(GetALLNft);
  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useQuery(GetAllUsers);
  return (
    <div className="max-w-[1600px] mx-auto p-5 mt-5">
      <div className="px-6 py-12 md:px-12 bg-white-50 text-gray-800 text-center lg:text-left">
        <div className="container mx-auto xl:px-32">
          <div className="grid lg:grid-cols-2 gap-12 flex items-center">
            <div className="mt-12 lg:mt-0">
              <h1 className="text-3xl md:text-6xl xl:text-5xl font-bold tracking-tight mb-12">
                One Stop For Admin <br />
                <span className="text-blue-600">Approve & Reject NFT's</span>
              </h1>
              <a
                className="inline-block px-7 py-3 mr-2 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                data-mdb-ripple="true"
                data-mdb-ripple-color="light"
                href="#!"
                role="button"
              >
                Get started
              </a>
              <a
                className="inline-block px-7 py-3 bg-transparent text-blue-600 font-medium text-sm leading-snug uppercase rounded hover:text-blue-700 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none focus:ring-0 active:bg-gray-200 transition duration-150 ease-in-out"
                data-mdb-ripple="true"
                data-mdb-ripple-color="light"
                href="#!"
                role="button"
              >
                Learn more
              </a>
            </div>
            <div className="mb-12 lg:mb-0">
              <img
                src="https://i.pinimg.com/originals/52/91/4b/52914b8ac2f16a11c42786c3d89a84f5.gif"
                className="w-full rounded-lg shadow-lg"
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="flex gap-[90px] justify-center m-2 mt-[50px]">
          <div className="block max-w-sm rounded-lg bg-white shadow-lg dark:bg-white-700 text-black">
            <a href="#!" data-te-ripple-init data-te-ripple-color="light">
              <img
                className="rounded-t-lg h-[400px]"
                src="https://i.gifer.com/878o.gif"
                alt=""
              />
            </a>
            <div className="p-6">
              <h5 className="mb-2 text-xl font-medium leading-tight text-neutral-800 dark:text-black-50">
                {data?.nfts?.length}+NFT
              </h5>

              <button
                type="button"
                className="inline-block rounded bg-primary px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                data-te-ripple-init
                data-te-ripple-color="light"
              >
                Go To Nft's
              </button>
            </div>
          </div>
          <div className="block max-w-sm rounded-lg bg-white shadow-lg dark:bg-white-700 text-black">
            <a href="#!" data-te-ripple-init data-te-ripple-color="light">
              <img
                className="rounded-t-lg h-[400px]"
                src="https://www.nftpilot.io/wp-content/uploads/2022/03/1024x1024.gif"
                alt=""
              />
            </a>
            <div className="p-6">
              <h5 className="mb-2 text-xl font-medium leading-tight text-neutral-800 dark:text-black-50">
                5+ Categories
              </h5>

              <button
                type="button"
                className="inline-block rounded bg-primary px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                data-te-ripple-init
                data-te-ripple-color="light"
              >
                Go To Categories
              </button>
            </div>
          </div>
          <div className="block max-w-sm rounded-lg bg-white shadow-lg dark:bg-white-700 text-black">
            <a href="#!" data-te-ripple-init data-te-ripple-color="light">
              <img
                className="rounded-t-lg h-[400px]"
                src="https://dpz0n88ffnk83.cloudfront.net/preview/QmNnixg81YqUTKizd9czkKr8ULbnp6eC7yKHiqHq7ADygb_large"
                alt=""
              />
            </a>
            <div className="p-6">
              <h5 className="mb-2 text-xl font-medium leading-tight text-neutral-800 dark:text-black-50">
                {userData?.users?.length}+ Users
              </h5>

              <button
                type="button"
                className="inline-block rounded bg-primary px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                data-te-ripple-init
                data-te-ripple-color="light"
              >
                Go To User's
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="grid lg:grid-cols-5 md:grid-cols-3  grid-cols-2  gap-10 ">
        <div className="p-2 border shadow rounded-lg  ">
          <img className="rounded-md w-full" src="images/nft.png" alt="" />
          <div className="mt-4">
            <p className="text-[16px]">Dreadfilz</p>
            <h1 className="text-[20px] font-bold">Dreadfilz #6767</h1>
          </div>
          <div className="mt-6 flex justify-between bg-[#F2F2F2] p-2 rounded-lg">
            <div className="">
              <p className="text-[15px]">Price</p>
              <h2 className="text-[18px]">0.015 ETH</h2>
            </div>
            <div className="">
              <p className="text-[15px]">Price</p>
              <h2 className="text-[18px]">0.015 ETH</h2>
            </div>
          </div>
          <div className="py-1  bg-[#FF0000]  border rounded-lg text-center mt-5">
            <a className="text-[20px]  text-white" href="#">
              Reject
            </a>
          </div>
        </div>

        <div className="p-2 border shadow rounded-lg  ">
          <img className="rounded-md w-full" src="images/nft.png" alt="" />
          <div className="mt-4">
            <p className="text-[16px]">Dreadfilz</p>
            <h1 className="text-[20px] font-bold">Dreadfilz #6767</h1>
          </div>
          <div className="mt-6 flex justify-between bg-[#F2F2F2] p-2 rounded-lg">
            <div className="">
              <p className="text-[15px]">Price</p>
              <h2 className="text-[18px]">0.015 ETH</h2>
            </div>
            <div className="">
              <p className="text-[15px]">Price</p>
              <h2 className="text-[18px]">0.015 ETH</h2>
            </div>
          </div>
          <div className="py-1  bg-[#FF0000]  border rounded-lg text-center mt-5">
            <a className="text-[20px]  text-white" href="#">
              Reject
            </a>
          </div>
        </div>
        <div className="p-2 border shadow rounded-lg  ">
          <img className="rounded-md w-full" src="images/nft.png" alt="" />
          <div className="mt-4">
            <p className="text-[16px]">Dreadfilz</p>
            <h1 className="text-[20px] font-bold">Dreadfilz #6767</h1>
          </div>
          <div className="mt-6 flex justify-between bg-[#F2F2F2] p-2 rounded-lg">
            <div className="">
              <p className="text-[15px]">Price</p>
              <h2 className="text-[18px]">0.015 ETH</h2>
            </div>
            <div className="">
              <p className="text-[15px]">Price</p>
              <h2 className="text-[18px]">0.015 ETH</h2>
            </div>
          </div>
          <div className="py-1  bg-[#FF0000]  border rounded-lg text-center mt-5">
            <a className="text-[20px]  text-white" href="#">
              Reject
            </a>
          </div>
        </div>
        <div className="p-2 border shadow rounded-lg  ">
          <img className="rounded-md w-full" src="images/nft.png" alt="" />
          <div className="mt-4">
            <p className="text-[16px]">Dreadfilz</p>
            <h1 className="text-[20px] font-bold">Dreadfilz #6767</h1>
          </div>
          <div className="mt-6 flex justify-between bg-[#F2F2F2] p-2 rounded-lg">
            <div className="">
              <p className="text-[15px]">Price</p>
              <h2 className="text-[18px]">0.015 ETH</h2>
            </div>
            <div className="">
              <p className="text-[15px]">Price</p>
              <h2 className="text-[18px]">0.015 ETH</h2>
            </div>
          </div>
          <div className="py-1  bg-[#FF0000]  border rounded-lg text-center mt-5">
            <a className="text-[20px]  text-white" href="#">
              Reject
            </a>
          </div>
        </div>
        <div className="p-2 border shadow rounded-lg  ">
          <img className="rounded-md w-full" src="images/nft.png" alt="" />
          <div className="mt-4">
            <p className="text-[16px]">Dreadfilz</p>
            <h1 className="text-[20px] font-bold">Dreadfilz #6767</h1>
          </div>
          <div className="mt-6 flex justify-between bg-[#F2F2F2] p-2 rounded-lg">
            <div className="">
              <p className="text-[15px]">Price</p>
              <h2 className="text-[18px]">0.015 ETH</h2>
            </div>
            <div className="">
              <p className="text-[15px]">Price</p>
              <h2 className="text-[18px]">0.015 ETH</h2>
            </div>
          </div>
          <div className="py-1  bg-[#FF0000]  border rounded-lg text-center mt-5">
            <a className="text-[20px]  text-white" href="#">
              Reject
            </a>
          </div>
        </div>
        <div className="p-2 border shadow rounded-lg  ">
          <img className="rounded-md w-full" src="images/nft.png" alt="" />
          <div className="mt-4">
            <p className="text-[16px]">Dreadfilz</p>
            <h1 className="text-[20px] font-bold">Dreadfilz #6767</h1>
          </div>
          <div className="mt-6 flex justify-between bg-[#F2F2F2] p-2 rounded-lg">
            <div className="">
              <p className="text-[15px]">Price</p>
              <h2 className="text-[18px]">0.015 ETH</h2>
            </div>
            <div className="">
              <p className="text-[15px]">Price</p>
              <h2 className="text-[18px]">0.015 ETH</h2>
            </div>
          </div>
          <div className="py-1  bg-[#FF0000]  border rounded-lg text-center mt-5">
            <a className="text-[20px]  text-white" href="#">
              Reject
            </a>
          </div>
        </div>
        <div className="p-2 border shadow rounded-lg  ">
          <img className="rounded-md w-full" src="images/nft.png" alt="" />
          <div className="mt-4">
            <p className="text-[16px]">Dreadfilz</p>
            <h1 className="text-[20px] font-bold">Dreadfilz #6767</h1>
          </div>
          <div className="mt-6 flex justify-between bg-[#F2F2F2] p-2 rounded-lg">
            <div className="">
              <p className="text-[15px]">Price</p>
              <h2 className="text-[18px]">0.015 ETH</h2>
            </div>
            <div className="">
              <p className="text-[15px]">Price</p>
              <h2 className="text-[18px]">0.015 ETH</h2>
            </div>
          </div>
          <div className="py-1  bg-[#FF0000]  border rounded-lg text-center mt-5">
            <a className="text-[20px]  text-white" href="#">
              Reject
            </a>
          </div>
        </div>
        <div className="p-2 border shadow rounded-lg  ">
          <img className="rounded-md w-full" src="images/nft.png" alt="" />
          <div className="mt-4">
            <p className="text-[16px]">Dreadfilz</p>
            <h1 className="text-[20px] font-bold">Dreadfilz #6767</h1>
          </div>
          <div className="mt-6 flex justify-between bg-[#F2F2F2] p-2 rounded-lg">
            <div className="">
              <p className="text-[15px]">Price</p>
              <h2 className="text-[18px]">0.015 ETH</h2>
            </div>
            <div className="">
              <p className="text-[15px]">Price</p>
              <h2 className="text-[18px]">0.015 ETH</h2>
            </div>
          </div>
          <div className="py-1  bg-[#FF0000]  border rounded-lg text-center mt-5">
            <a className="text-[20px]  text-white" href="#">
              Reject
            </a>
          </div>
        </div>
        <div className="p-2 border shadow rounded-lg  ">
          <img className="rounded-md w-full" src="images/nft.png" alt="" />
          <div className="mt-4">
            <p className="text-[16px]">Dreadfilz</p>
            <h1 className="text-[20px] font-bold">Dreadfilz #6767</h1>
          </div>
          <div className="mt-6 flex justify-between bg-[#F2F2F2] p-2 rounded-lg">
            <div className="">
              <p className="text-[15px]">Price</p>
              <h2 className="text-[18px]">0.015 ETH</h2>
            </div>
            <div className="">
              <p className="text-[15px]">Price</p>
              <h2 className="text-[18px]">0.015 ETH</h2>
            </div>
          </div>
          <div className="py-1  bg-[#FF0000]  border rounded-lg text-center mt-5">
            <a className="text-[20px]  text-white" href="#">
              Reject
            </a>
          </div>
        </div>
        <div className="p-2 border shadow rounded-lg  ">
          <img className="rounded-md w-full" src="images/nft.png" alt="" />
          <div className="mt-4">
            <p className="text-[16px]">Dreadfilz</p>
            <h1 className="text-[20px] font-bold">Dreadfilz #6767</h1>
          </div>
          <div className="mt-6 flex justify-between bg-[#F2F2F2] p-2 rounded-lg">
            <div className="">
              <p className="text-[15px]">Price</p>
              <h2 className="text-[18px]">0.015 ETH</h2>
            </div>
            <div className="">
              <p className="text-[15px]">Price</p>
              <h2 className="text-[18px]">0.015 ETH</h2>
            </div>
          </div>
          <div className="py-1  bg-[#FF0000]  border rounded-lg text-center mt-5">
            <a className="text-[20px]  text-white" href="#">
              Reject
            </a>
          </div>
        </div>
      </div> */}
    </div>
  );
}
