import { gql } from "@apollo/client";

export const GetALLNft = gql`
  query Nfts {
    nfts {
      _id
      name
      tokenId
      ipfsUrl
      listingId
      imageUrl
      category
      chainId
      network
      ownerAddress
      creatorAddress
      contractAddress
      isMarketPlace
      isApproved
      isAuction
      isListed
      price
    }
  }
`;
