import { gql } from "@apollo/client";

export const ApproveNFT = gql`
  mutation NftApproved($isApproved: Boolean, $nftId: String) {
    nftApproved(isApproved: $isApproved, nftId: $nftId) {
      _id
      name
      tokenId
      ipfsUrl
      imageUrl
      category
      chainId
      network
      ownerAddress
      creatorAddress
      isMarketPlace
      isApproved
      price
      listingId
      contractAddress
      lazyMinting
      voucher
      isAuction
      isListed
    }
  }
`;
