import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { ApproveNFT } from "../GraphQL/Mutations/ApproveNFT";
import { GetALLNft } from "../GraphQL/Queries/GetALLNft";

export default function Admin() {
  const { data, loading, error } = useQuery(GetALLNft);
  console.log(data);
  const [ApproveNFTID] = useMutation(ApproveNFT);
  return (
    <div className="max-w-[1600px] mx-auto p-5 mt-5">
      <div className="grid lg:grid-cols-5 md:grid-cols-3  grid-cols-2  gap-10 ">
        {data?.nfts?.map((data, key) => (
          <div className="p-2 border shadow rounded-lg  ">
            <img
              className="rounded-md w-full h-[200px]"
              src={data.imageUrl}
              alt=""
            />
            <div className="mt-4">
              <p className="text-[16px] uppercase">{data.category}</p>
              <h1 className="text-[20px] font-bold">{data.name}</h1>
            </div>
            <div className="mt-6 flex justify-between bg-[#F2F2F2] p-2 rounded-lg">
              <div className="">
                <p className="text-[15px]">Price</p>
                <h2 className="text-[18px]">{data.price}</h2>
              </div>
              <div className="">
                <p className="text-[15px]">Network</p>
                <h2 className="text-[18px]">{data.network}</h2>
              </div>
            </div>
            <div className="flex justify-between mt-5">
              {data.isApproved ? (
                <button
                  className="text-[20px] py-1 px-4 bg-[#FFC107] text-white border rounded-lg"
                  href="#"
                  onClick={() => {
                    ApproveNFTID({
                      variables: {
                        isApproved: false,
                        nftId: data._id,
                      },
                      refetchQueries: [GetALLNft],
                    })
                      .then((res) => {
                        console.log(res);
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }}
                >
                  Reject
                </button>
              ) : (
                <button
                  className="text-[20px] py-1 px-6 bg-[#FF0000] text-white border rounded-lg"
                  onClick={() => {
                    ApproveNFTID({
                      variables: {
                        isApproved: true,
                        nftId: data._id,
                      },
                      refetchQueries: [GetALLNft],
                    })
                      .then((res) => {
                        console.log(res);
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }}
                >
                  Approve
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
