import { gql } from "@apollo/client";

/* export const GetStaticPage = gql`
query GetStaticPage($type: String) {
    getStaticPage(type: $type) {
      _id
      body {
        heading
        html
        sub_heading
      }
      title
      type
    }
  }
`; */

export const GetStaticPage = gql`
query GetStaticPage($type: String) {
    getStaticPage(type: $type) {
      _id
      body
      title
      type
    }
}
`;

