import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("loggedIn")) {
      navigate("/dashboard");
    }
  }, []);

  return (
    <>
      <section className="md:mt-[0px] xl:mt-[100px] mt-[20px] px-2">
        <div className="mx-auto max-w-[1600px] p-5">
          <div className="flex lg:flex-row flex-col justify-between">
            <div className="z-10 xl:w-[50%] md:w-[70%] bg-white shadow py-6 px-4 md:px-0 w-full xl:mx-0 mx-auto rounded md:px-4 px-4">
              <h1 className="md:text-[30px] text-[20px] md:pb-10 pb-[20px] xl:text-left text-center font-bold">
                Admin Login
              </h1>

              <div className="">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    const data = {
                      email: e.target[0].value,
                      password: e.target[1].value,
                    };
                    if (
                      data.email == "admin@sainft.com" &&
                      data.password == "root"
                    ) {
                      navigate("/dashboard");
                      localStorage.setItem("loggedIn", true);
                    } else {
                      alert("Wrong Credentials");
                    }
                    console.log(data);
                  }}
                >
                  <div className="mb-6">
                    <label
                      for="email"
                      className="block mb-2 text-sm text-left font-bold text-[#3F3F3F]"
                    >
                      Your Phone or Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg block w-full px-2.5 py-4"
                      required
                    />
                  </div>
                  <div className="mb-10">
                    <div className="flex justify-between">
                      <label
                        for="password"
                        className="block mb-2 text-[#3F3F3F] font-bold text-sm"
                      >
                        Your Password
                      </label>
                    </div>
                    <input
                      type="password"
                      id="password"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full px-2.5 py-4"
                      required
                    />
                  </div>

                  <div className="w-full bg-[#13A307] px-8 py-4 flex justify-center rounded-lg">
                    <button
                      className="text-white font-medium rounded-lg uppercase text-center text-sm"
                      href="3-buy-crypto.html"
                      type="submit"
                    >
                      Login
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <div className="lg:w-[600px] w-f-full  flex justify-center ">
              <img src="./images/nft-green.jpg" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
