import { gql } from "@apollo/client";
export const VerifyUser = gql`
  mutation NftApproved($userId: String, $isVerified: Boolean) {
    updateUser(userId: $userId, isVerified: $isVerified) {
      _id
      displayName
      username
      avatar_url
      about_details
      bg_image
      twitterUrl
      facebookUrl
      firstname
      lastname
      websiteUrl
      isVerified
    }
  }
`;
