import React, { useEffect, useState } from "react";
import Profiles from "./Profiles";
import Admin from "./Admin";
import Approve from "./Approve";
import { useNavigate } from "react-router-dom";
import AboutUs from "./AboutUs";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsConditions from "./TermsConditions";
import HowItWorks from "./HowItWorks";

const Profile = () => {
  return (
    <div NFT>
      <Profiles />
    </div>
  );
};

const NFT = () => {
  return (
    <div NFT>
      <Admin />
    </div>
  );
};

const Approvednft = () => {
  return (
    <div>
      <Approve />
    </div>
  );
};

export default function App() {
  const [activeTab, setActiveTab] = useState("Home");
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("loggedIn") == null || undefined || false) {
      navigate("/");
    }
  }, []);

  const handleProfile = () => {
    setActiveTab("Profile");
  };
  const handleNFT = () => {
    setActiveTab("NFT");
  };
  const handleHome = () => {
    setActiveTab("Home");
  };
  const handleApp = () => {
    setActiveTab("Approve");
  };

  return (
    <div className="App">
      <div className="tabs max-w-[1600px] mx-auto mt-5">
        <div className="tab-link" style={{ display: "flex" }}>
          <p
            onClick={handleHome}
            className={
              activeTab === "Home" ? "border-b-4 border-indigo-500" : ""
            }
            style={{ cursor: "pointer", padding: "20px", margin: "0px 10px" }}
          >
            Home
          </p>
          <p
            onClick={handleProfile}
            className={
              activeTab === "Profile" ? "border-b-4 border-indigo-500" : ""
            }
            style={{ cursor: "pointer", padding: "20px", margin: "0px 10px" }}
          >
            Profile
          </p>
          <p
            onClick={handleNFT}
            className={
              activeTab === "NFT" ? "border-b-4 border-indigo-500" : ""
            }
            style={{ cursor: "pointer", padding: "20px", margin: "0px 10px" }}
          >
            NFT
          </p>
          <p
            onClick={() => {
              setActiveTab("AboutUs");
            }}
            className={
              activeTab === "AboutUs" ? "border-b-4 border-indigo-500" : ""
            }
            style={{ cursor: "pointer", padding: "20px", margin: "0px 10px" }}
          >
            About Us
          </p>
          <p
            onClick={() => {
              setActiveTab("PrivacyPolicy");
            }}
            className={
              activeTab === "PrivacyPolicy" ? "border-b-4 border-indigo-500" : ""
            }
            style={{ cursor: "pointer", padding: "20px", margin: "0px 10px" }}
          >
            Privacy & Policy
          </p>
          <p
            onClick={() => {
              setActiveTab("TermsConditions");
            }}
            className={
              activeTab === "TermsConditions" ? "border-b-4 border-indigo-500" : ""
            }
            style={{ cursor: "pointer", padding: "20px", margin: "0px 10px" }}
          >
            Terms & Conditions
          </p>
          <p
            onClick={() => {
              setActiveTab("HowItWorks");
            }}
            className={
              activeTab === "HowItWorks" ? "border-b-4 border-indigo-500" : ""
            }
            style={{ cursor: "pointer", padding: "20px", margin: "0px 10px" }}
          >
            How It Works
          </p>
        </div>
        <div className="outlet">
          {activeTab === "Profile" ? (
            <Profile />
          ) : activeTab == "NFT" ? (
            <Admin />
          ) : activeTab == "AboutUs" ? (
            <AboutUs />
          ) : activeTab == "PrivacyPolicy" ? (
            <PrivacyPolicy />
          ) : activeTab == "TermsConditions" ? (
            <TermsConditions />
          ) : activeTab == "HowItWorks" ? (
            <HowItWorks />
          ) : (
            <Approve />
          )}
        </div>
      </div>
    </div>
  );
}
