import { gql } from "@apollo/client";

/* export const StaticPageUpdate = gql`
mutation StaticPageUpdate($type: String, $title: String, $body: Object) {
  staticPageUpdate(type: $type, title: $title, body: $body) {
    _id
    body {
      heading
      html
      sub_heading
    }
    title
    type
  }
}
`; */

export const StaticPageUpdate = gql`
mutation StaticPageUpdate($type: String, $title: String, $body: Object) {
  staticPageUpdate(type: $type, title: $title, body: $body) {
    _id
    body
    title
    type
  }
}
`;