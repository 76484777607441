import "./App.css";
import Header from "./Components/Header";
import Tabs from "./Components/Tabs";
import UserRoutes from "./Routes/UserRoutes";

function App() {
  return (
    <div className="App">
      <UserRoutes />
    </div>
  );
}

export default App;
