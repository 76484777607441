import { gql } from "@apollo/client";

export const GetAllUsers = gql`
  query Users {
    users {
      _id
      displayName
      username
      avatar_url
      about_details
      bg_image
      twitterUrl
      facebookUrl
      firstname
      lastname
      websiteUrl
      isVerified
    }
  }
`;
